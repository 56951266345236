import React from "react";
import Profilebody from "../DesktopComponents/AccountDetails/Profilebody";
import UserPostedBlogs from "../DesktopComponents/AccountDetails/UserPosted";
import CreatePostAccount from "../DesktopComponents/AccountDetails/CreatePostAccount";
//
//
//

function AccountPage() {
  return (
    <div className="home-page-container">
      <Profilebody />
      <CreatePostAccount />
      <>
        <UserPostedBlogs />
      </>
    </div>
  );
}

export default AccountPage;
