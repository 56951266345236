import React, { useState, useEffect } from "react";
import { useUser } from "../Contexts/UserContext";

const CoverSection = () => {
  const { user } = useUser();
  const [coverImageUrl, setCoverImageUrl] = useState("");

  const Hid = "e104ea8c-af6e-4a5d-8f19-a39593f0bb45"; // You can replace this with `user.userId` if needed

  useEffect(() => {
    const fetchCoverImage = async () => {
      try {
        const response = await fetch(
          `https://coblog-backend.vercel.app/Users/coverPicture?userId=${Hid}`, // Include userId as a query parameter
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        console.log(Hid);

        if (data.profilePictureUrl) {
          setCoverImageUrl(data.profilePictureUrl);
        }
      } catch (error) {
        console.error("Error fetching cover image:", error);
      }
    };

    fetchCoverImage();
  }, []);

  return (
    <div style={styles.coverSection}>
      {coverImageUrl ? (
        <img src={coverImageUrl} alt="Cover" style={styles.coverImage} />
      ) : (
        <p>Loading cover image...</p>
      )}
    </div>
  );
};

const styles = {
  coverSection: {
    width: "100%",
    height: "300px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f0f0f0",
    overflow: "hidden",
  },
  coverImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
};

export default CoverSection;
