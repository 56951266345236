import React from "react";
import "./loginmodal.css"; // Add your styles for the modal here
import Login from "../../Pages/Login";

function ModalLogin({ onClose }) {
  return (
    <div className="modal-overlay">
      <div className="Login-model-close-box">
        <button onClick={onClose} className="Login-model-close">
          X
        </button>
      </div>
      <div className="modal-content">
        {/* <h2>Welcome Back!</h2>
        <p>You can now create a new blog.</p> */}

        <Login />
      </div>
    </div>
  );
}

export default ModalLogin;
