import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { UserProvider } from "./DesktopComponents/Contexts/UserContext";
//
import "./Assets/Fonts/fonts.css";
//

import FeadBackPage from "./Pages/FeadBackPage";
import Sidebar from "./Sidebar/Sidebar";
import Home from "./Pages/Home";
import BlogDetails from "./DesktopComponents/Blog/BlogDetails";
import CreatePage from "./Pages/CreatePage";
import Explore from "./Pages/Explore";
import CreateNewBlog from "./DesktopComponents/Blog/CreateNewBlog";
import Friendspage from "./Pages/Friends";
import AccountPage from "./Pages/Account";
import CommentsPage from "./Pages/CommentsPage";

import "./App.css";

function App() {
  const [redirected, setRedirected] = useState(false);
  //
  //

  useEffect(() => {
    const handleResize = () => {
      const desktopUrl = "https://dblob.digihut.store/"; // Desktop version URL
      const mobileUrl = "https://blogbook.digihut.store/home"; // Mobile version URL

      // Check if the current URL is not the target URL
      if (
        window.innerWidth > 700 &&
        window.location.href !== desktopUrl &&
        !redirected
      ) {
        window.location.href = desktopUrl;
        setRedirected(true);
      } else if (
        window.innerWidth <= 700 &&
        window.location.href !== mobileUrl &&
        !redirected
      ) {
        window.location.href = mobileUrl;
        setRedirected(true);
      }
    };

    // Check screen width on component mount
    handleResize();

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  //
  //

  return (
    <UserProvider>
      <Router>
        <div className="app">
          <Sidebar />
          <div className="content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/Explore" element={<Explore />} />
              <Route path="/Create" element={<CreatePage />} />
              <Route path="/BlogDetails/:id" element={<BlogDetails />} />
              <Route path="/Create" element={<CreateNewBlog />} />
              <Route path="/CommentsPage" element={<CommentsPage />} />
              <Route path="/Friend" element={<Friendspage />} />
              <Route path="/AccountPage" element={<AccountPage />} />
              <Route path="/FeadBackPage" element={<FeadBackPage />} />
            </Routes>
          </div>
        </div>
      </Router>
    </UserProvider>
  );
}

export default App;
