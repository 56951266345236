import React from "react";
import "../StyleSheets/Home.css";
import CreateFixedButton from "../DesktopComponents/General/CreateFixedButton";
import CreatePost from "../DesktopComponents/DesktopPosts/CreatePost";
import BlogList from "../DesktopComponents/Blog/BlogList";
import AppDownload from "../DesktopComponents/AndroidApp/AppDownload";

function Home() {
  return (
    <section className="home-page-maincontainer">
      <div className="home-page-container">
        <CreatePost />
        <AppDownload />
        <BlogList />
        <CreateFixedButton />
      </div>
      <section className="rightsidebar">
        <div className="friends-or-authers"></div>
      </section>
    </section>
  );
}

export default Home;
