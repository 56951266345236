import React from "react";
import { useNavigate } from "react-router-dom";
import createbtn from "../../Assets/create.png";
import "./CreateFixedButton.css";

function CreateFixedButton() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/create");
  };

  return (
    <div className="CreateFixedButton-container">
      <button className="CreateFixedButton" onClick={handleClick}>
        <img src={createbtn} alt="" className="CreateFixedButton-image" />
      </button>
    </div>
  );
}

export default CreateFixedButton;
