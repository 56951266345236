import React, { useEffect, useState } from "react";
import axios from "axios";
import AuthenticatedBlogCard from "./BlogCardAuthenticated";
import { useUser } from "../../DesktopComponents/Contexts/UserContext";

// Helper function to convert binary data to Base64 string
const arrayBufferToBase64 = (buffer) => {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

const UserPostedBlogs = () => {
  const { user } = useUser();
  const [blogs, setBlogs] = useState([]);

  const fetchBlogs = async () => {
    if (!user || !user.userId) return;

    try {
      const response = await axios.get(
        `https://coblog-backend.vercel.app/users/blogs/${user.userId}`
      );

      setBlogs(response.data);
    } catch (error) {
      console.error("Failed to fetch blogs:", error);
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  return (
    <div className="container-Bloglist">
      <div className="blog-grid">
        {blogs.length > 0 ? (
          blogs.map((blog) => {
            const base64String = blog.blogpicture
              ? `data:image/jpeg;base64,${arrayBufferToBase64(
                  blog.blogpicture.data
                )}`
              : "default_picture_url_here";
            return (
              <div key={blog.blog_id}>
                <AuthenticatedBlogCard
                  authorName={blog.author_name}
                  title={blog.title}
                  text={blog.content}
                  createdOn={blog.created_at}
                  imageUrl={base64String}
                  blogLink={`https://blogbook.digihut.store/blog/${blog.blog_id}`}
                  userId={blog.user_id} // Pass user_id to BlogTemplate
                  blogId={blog.blog_id}
                />
              </div>
            );
          })
        ) : (
          <p>No blogs available.</p>
        )}
      </div>
    </div>
  );
};

export default UserPostedBlogs;
