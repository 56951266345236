import React, { useState } from "react";
import "./BlogTemplate.css";
import { useNavigate } from "react-router-dom";
import likeIcon from "../../Assets/Icons/like.png";
import likedIcon from "../../Assets/Icons/liked.png"; // Add the liked icon
import commentIcon from "../../Assets/Icons/comment.png";
import shareIcon from "../../Assets/Icons/share.png";
import saveIcon from "../../Assets/Icons/Save.png";
import UserHead from "../DesktopPosts/UserHead";
import Modal from "../Modal/Modal";
import ReactMarkdown from "react-markdown";
import axios from "axios";

const BlogCards = ({
  title = "No Title",
  text = "No content available.",
  imageUrl,
  authorName = "Unknown Author",
  createdOn = "Unknown Date",
  blogLink = "#",
  userId,
  BlogId,
  likes = 0,
  TotalComment = 0,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLiked, setIsLiked] = useState(false); // Add state for like status
  const [imgError, setImgError] = useState(false); // State to track image load error

  // console.log(FollowerId);

  const navigate = useNavigate();

  const handleLike = async () => {
    try {
      await axios.post(
        `https://coblog-backend.vercel.app/like/${BlogId}`,
        { userId },
        { headers: { "Content-Type": "application/json" } }
      );
      setIsLiked(!isLiked); // Toggle the like status
    } catch (error) {
      console.error("Error posting like:", error);
    }
  };

  const openModal = () => {
    setShowModal(true);
  };

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  const renderText = () => {
    const truncatedText =
      text.length > 100 ? `${text.substring(0, 100)}...` : text;
    return <ReactMarkdown>{isExpanded ? text : truncatedText}</ReactMarkdown>;
  };

  const commentClick = () => {
    navigate("/CommentsPage", {
      state: { title, text, imageUrl, authorName, createdOn, BlogId },
    });
  };

  return (
    <div className="blog-card">
      <UserHead
        name={authorName}
        createdOn={createdOn}
        blogLink={blogLink}
        userId={userId} // Pass userId to UserHead
      />
      <div className="blog-content-box">
        <h2 className="blog-title">{title}</h2>
        <div className="blog-content">{renderText()}</div>
      </div>
      {text.length > 100 && (
        <button onClick={toggleText} className="read-more-btn">
          {isExpanded ? "Read Less" : "Read More"}
        </button>
      )}
      {imageUrl && !imgError && (
        <img
          src={imageUrl}
          alt={title}
          className="blog-image"
          onError={() => setImgError(true)} // Set error state on image load failure
        />
      )}
      <div className="blog-actions">
        <div className="Btns-box">
          <div className="action-btn-box" onClick={handleLike}>
            <img
              src={isLiked ? likedIcon : likeIcon} // Change icon based on like status
              alt="Like"
              className="action-icon"
            />
            <h5>{likes}</h5>
          </div>
          <div className="action-btn-box" onClick={commentClick}>
            <img src={commentIcon} alt="Comment" className="action-icon" />
            <h5>{TotalComment}</h5>
          </div>
          <div className="action-btn-box" onClick={openModal}>
            <img src={shareIcon} alt="Share" className="action-icon" />
          </div>
        </div>
        <div className="Save-btn-box">
          <div className="Save-btn">
            <img src={saveIcon} alt="Save" className="action-icon" />
          </div>
        </div>
        <Modal
          show={showModal}
          onClose={() => setShowModal(false)}
          blogLink={blogLink}
        >
          <button onClick={() => setShowModal(false)}>Close</button>
        </Modal>
      </div>
    </div>
  );
};

export default BlogCards;
