// Modal.js
import React from "react";
import "../Modal/Modal.css";
import ShareComponentAccount from "./ShareComponentAccount";

function Modal({ show, onClose, blogLink }) {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal">
        <div className="line"></div>
        <div className="close-btn-box">
          <button className="modal-close" onClick={onClose}>
            Close
          </button>
        </div>

        <div className="modal-content">
          <ShareComponentAccount blogLink={blogLink}></ShareComponentAccount>
        </div>
      </div>
    </div>
  );
}

export default Modal;
