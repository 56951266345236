import React from "react";
import Followers from "../DesktopComponents/Friends/Followers";
// import Following from "../DesktopComponents/Friends/Following";

// import "../Stylesheets/Home.css";

function Friendspage() {
  return (
    <div className="home-page-container">
      <div className="Middle-content-Friendspage"></div>

      {/* <Following /> */}
      <Followers />
    </div>
  );
}

export default Friendspage;
