import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { useUser } from "../DesktopComponents/Contexts/UserContext";
import "./Sidebar.css";
import createbtn from "../Assets/create.png";
import logo from "../Assets/Logo.png"; // Adjust the path as needed
import Friends from "../Assets/Friends.png"; // Adjust the path as needed
import homeIcon from "../Assets/home.png";
import aboutIcon from "../Assets/find.png";

function Sidebar() {
  const { user } = useUser();
  const [profilePictureUrl, setProfilePictureUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  //
  //
  //
  useEffect(() => {
    const fetchProfilePicture = async () => {
      if (user && user.userId) {
        try {
          const response = await axios.get(
            `https://coblog-backend.vercel.app/users/profilePicture`,
            {
              headers: {
                "Content-Type": "application/json",
              },
              params: {
                userId: user.userId, // Send userId as query parameter
              },
            }
          );

          if (response.data && response.data.profilePictureUrl) {
            setProfilePictureUrl(response.data.profilePictureUrl);
          } else {
            setProfilePictureUrl(""); // Handle no profile picture
          }
        } catch (error) {
          console.error("Failed to fetch profile picture:", error);
          setProfilePictureUrl(""); // Handle errors
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    fetchProfilePicture();
  }, []);
  //
  //

  const menus = [
    { name: "Home", icon: homeIcon, path: "/" },
    { name: "Explore", icon: aboutIcon, path: "/Explore" },
    { name: "Create", icon: createbtn, path: "/Create" },
    { name: "Friends", icon: Friends, path: "/Friend" },
  ];

  //
  //
  //

  return (
    <div className="sidebar">
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      {menus.map((menu) => (
        <NavLink
          to={menu.path}
          key={menu.name}
          className={({ isActive }) => `menu-link ${isActive ? "active" : ""}`}
        >
          <div className="menu-item">
            <img
              src={menu.icon}
              alt={`${menu.name} icon`}
              className="menu-icon"
            />
            <span className="menu-text">
              {menu.name.charAt(0).toUpperCase() + menu.name.slice(1)}
            </span>
          </div>
        </NavLink>
      ))}
      <div className="side-bar-profile">
        <img
          src={profilePictureUrl}
          alt="profile"
          className="profile-sidebar"
        />

        <NavLink to="/AccountPage" className="menu-link">
          <span className="span-profile-name-sidebar">Profile</span>
        </NavLink>
      </div>
    </div>
  );
}

export default Sidebar;
