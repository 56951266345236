import React from "react";
import SearchBody from "../DesktopComponents/Explorecomponents/SearchBody";

function Explore() {
  return (
    <div className="home-page-container">
      <SearchBody />
    </div>
  );
}

export default Explore;
