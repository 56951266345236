import React from "react";
import FeedbackComponent from "../DesktopComponents/Feedback/FeedbackComponent";

function FeadBackPage() {
  return (
    <div className="home-page-container">
      <>
        <FeedbackComponent />
      </>
    </div>
  );
}

export default FeadBackPage;
