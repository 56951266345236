import React, { useState } from "react";
// import "../Blogs/BlogTemplate.css";
import { useNavigate } from "react-router-dom";
import likeIcon from "../../Assets/Icons/like.png";
import commentIcon from "../../Assets/Icons/comment.png";
import shareIcon from "../../Assets/Icons/share.png";
import saveIcon from "../../Assets/Icons/Save.png";
import ReactMarkdown from "react-markdown";
import UserHeadAccount from "./BlogHeadAccount";
import Modal from "./Model";

const AuthenticatedBlogCard = ({
  title,
  text = "",
  imageUrl,
  authorName,
  createdOn,
  blogLink,
  blogId,
  userId, // Receive userId
}) => {
  const [showModal, setShowModal] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const navigate = useNavigate();

  if (!title || !text) {
    return null;
  }

  const openModal = () => {
    setShowModal(true);
  };
  //
  //
  const showId = () => {};

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  const renderText = () => {
    const truncatedText =
      text.length > 100 ? text.substring(0, 100) + "..." : text;
    if (isExpanded) {
      return <ReactMarkdown>{text}</ReactMarkdown>;
    }
    return <ReactMarkdown>{truncatedText}</ReactMarkdown>;
  };

  const commentClick = () => {
    navigate("/Comments", {
      state: { title, text, imageUrl, authorName, createdOn },
    });
  };

  return (
    <div className="blog-card">
      <UserHeadAccount
        name={authorName}
        createdOn={createdOn}
        blogLink={blogLink}
        userId={userId} // Pass userId to UserHead
        blogId={blogId}
      />
      <div className="blog-content-box">
        <h2 className="blog-title">{title}</h2>
        <p className="blog-content">{renderText()}</p>
      </div>
      {text.length > 100 && (
        <button onClick={toggleText} className="read-more-btn">
          {isExpanded ? "Read Less" : "Read More"}
        </button>
      )}
      {imageUrl && <img src={imageUrl} alt={title} className="blog-image" />}
      <div className="blog-actions">
        <div className="Btns-box">
          <div className="action-btn-box">
            <img
              src={likeIcon}
              alt="Like"
              className="action-icon"
              onClick={showId}
            />
          </div>
          <div className="action-btn-box" onClick={commentClick}>
            <img src={commentIcon} alt="Comment" className="action-icon" />
          </div>
          <div className="action-btn-box" onClick={openModal}>
            <img src={shareIcon} alt="Share" className="action-icon" />
          </div>
        </div>
        <div className="Save-btn-box">
          <div className="Save-btn">
            <img src={saveIcon} alt="Save" className="action-icon" />
          </div>
        </div>
        <Modal
          show={showModal}
          onClose={() => setShowModal(false)}
          blogLink={blogLink}
        >
          <h2>Modal Title</h2>
          <p>This is a simple modal.</p>
          <button onClick={() => setShowModal(false)}>Close</button>
        </Modal>
      </div>
    </div>
  );
};

export default AuthenticatedBlogCard;
