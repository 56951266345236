import React from "react";
import "./AppDownload.css";

const AppDownload = () => {
  const handleDownload = () => {
    // Redirect to the app download link
    window.location.href =
      "https://drive.usercontent.google.com/download?id=1NrrOaFqG8kFXZG3p3Vw0ZQNPf33aY727&export=download&authuser=0"; // Replace with your actual app download link
  };

  return (
    <div className="app-download-container">
      <p className="app-download-message">
        For the best experience, please download our app.
      </p>
      <button className="app-download-button" onClick={handleDownload}>
        Download Now
      </button>
    </div>
  );
};

export default AppDownload;
