import React from "react";
import Comments from "../DesktopComponents/General/Commentspage";

function CommentsPage() {
  return (
    <div className="home-page-container">
      <Comments />
    </div>
  );
}

export default CommentsPage;
