// Modal.js
import React from "react";
import "./Modal.css";
import ShareComponent from "./ShareComponent";

function Modal({ show, onClose, blogLink }) {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay" onClick={onClose}>
      <button className="modal-close" onClick={onClose}>
        X
      </button>
      <div className="modal">
        <ShareComponent blogLink={blogLink}></ShareComponent>
      </div>
    </div>
  );
}

export default Modal;
