// src/components/ShareComponent.jsx
import React from "react";
import "../Modal/ShareComponent.css";

const ShareComponentAccount = ({ blogLink }) => {
  const shareOnWhatsApp = () => {
    const whatsappURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      blogLink
    )}`;
    window.open(whatsappURL, "_blank");
  };

  const shareOnFacebook = () => {
    const facebookURL = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      blogLink
    )}`;
    window.open(facebookURL, "_blank");
  };

  const shareOnTwitter = () => {
    const twitterURL = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      blogLink
    )}`;
    window.open(twitterURL, "_blank");
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(blogLink).then(
      () => {
        alert("Blog link copied to clipboard!");
      },
      (err) => {
        console.error("Failed to copy: ", err);
      }
    );
  };

  return (
    <div className="share-component">
      <h3 className="share-title">Share</h3>

      {/* <h5>{blogLink}</h5> */}
      <div className="share-buttons">
        <div className="share-btn-div">
          <button onClick={shareOnWhatsApp} className="share-button whatsapp">
            WhatsApp
          </button>
        </div>
        <div className="share-btn-div">
          <button onClick={shareOnFacebook} className="share-button facebook">
            Facebook
          </button>
        </div>
        <div className="share-btn-div">
          <button onClick={shareOnTwitter} className="share-button twitter">
            Twitter
          </button>
        </div>
        <div className="share-btn-div">
          <button onClick={copyToClipboard} className="share-button copy">
            Copy Link
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShareComponentAccount;
