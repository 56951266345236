import React, { createContext, useState, useContext, useEffect } from "react";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem("user");
    return storedUser
      ? { ...JSON.parse(storedUser), isLoggedIn: true }
      : { name: "", email: "", userId: "", isLoggedIn: false };
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      const storedUser = localStorage.getItem("user");
      if (storedUser) {
        const parsedUser = JSON.parse(storedUser);
        setUser({ ...parsedUser, isLoggedIn: true });
      } else {
        setUser({ name: "", email: "", userId: "", isLoggedIn: false });
      }
    }, 1000); // Check every second

    return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
