import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Profilebody.css"; // Import your CSS for styling
import { useNavigate } from "react-router-dom";
import CoverSection from "./CoverSection";
import camera from "../../Assets/Icons/Camera.png";
import { useUser } from "../Contexts/UserContext";

const Profilebody = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const [profilePictureUrl, setProfilePictureUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const Verified =
    "https://static.vecteezy.com/system/resources/thumbnails/022/597/484/small/3d-verification-social-media-png.png";

  useEffect(() => {
    const fetchProfilePicture = async () => {
      if (user && user.userId) {
        try {
          const response = await axios.get(
            `https://coblog-backend.vercel.app/users/profilePicture`,
            {
              headers: {
                "Content-Type": "application/json",
              },
              params: {
                userId: user.userId, // Send userId as query parameter
              },
            }
          );

          if (response.data && response.data.profilePictureUrl) {
            setProfilePictureUrl(response.data.profilePictureUrl);
          } else {
            setProfilePictureUrl(""); // Handle no profile picture
          }
        } catch (error) {
          console.error("Failed to fetch profile picture:", error);
          setProfilePictureUrl(""); // Handle errors
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    fetchProfilePicture();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("user");
    navigate(-1);
  };

  const goToFeadBack = () => {
    navigate("/FeadBackPage");
  };

  const handleProfilePictureChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("userId", user.userId);
    formData.append("profilePicture", file);

    try {
      const response = await axios.post(
        "https://coblog-backend.vercel.app/users/uploadProfilePic",
        formData
      );

      if (response.data.profile_picture && response.data.profile_picture.data) {
        const base64String = `data:image/jpeg;base64,${response.data.profile_picture.data}`;
        setProfilePictureUrl(base64String);
      }
    } catch (error) {
      console.error("Error uploading profile picture:", error);
    }
  };

  if (!user || user.userId.length === 0 || user.name === "") {
    return <div>This in not a user</div>;
  }

  return (
    <div className="profile-body">
      <CoverSection />
      <div className="profile-picture-section">
        <div className="profile-info-account">
          <div className="upload-pic-container">
            <div className="profile-photo-account-screen">
              {isLoading ? (
                <div className="loading-text">Loading profile picture...</div>
              ) : (
                <img
                  src={
                    profilePictureUrl ||
                    "https://cdn-icons-png.flaticon.com/128/3177/3177440.png"
                  } // Use placeholder if no URL
                  alt="Profile"
                  className="Profile-picture-account"
                />
              )}
              <label htmlFor="upload-input" className="camera-icon-label">
                <img
                  src={camera}
                  alt="Upload"
                  className="Camera-btn-pic-upload"
                />
              </label>
              <input
                type="file"
                id="upload-input"
                style={{ display: "none" }}
                onChange={handleProfilePictureChange}
              />
            </div>
          </div>

          <div className="user-name-account">
            <section className="name-box-account-verified">
              <h3>
                {user.name}{" "}
                <img src={Verified} className="verified" alt="VerifiedTick" />
              </h3>
            </section>
            <h6 className="user-email">✉ {user.email}</h6>
          </div>
        </div>
      </div>

      <div className="profile-Buttons-section">
        <div className="buttons">
          <button className="button" onClick={goToFeadBack}>
            FeadBack
          </button>
          <button className="button-logout" onClick={handleLogout}>
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default Profilebody;
