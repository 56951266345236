import React, { useState, useEffect } from "react";
import axios from "axios";
// import "../PostComponents/UserHead.css";
import dotsIcon from "../../Assets/Logo.png";
import DotclickAccoun from "./ThreeDotsAccountsModel";
// import { useUser } from "../../Contexts/UserContext";

const UserHeadAccount = ({ name, createdOn, blogLink, userId, blogId }) => {
  const [showDotclick, setShowDotclick] = useState(false);
  const [profilePictureUrl, setProfilePictureUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  // const { user } = useUser();

  // console.log(user);

  const openDotclick = () => {
    setShowDotclick(true);
  };

  const closeDotclick = () => {
    setShowDotclick(false);
  };

  let date;

  if (createdOn === undefined || createdOn === null) {
    date = new Date();
  } else {
    date = new Date(createdOn);
  }

  if (isNaN(date)) {
    date = new Date();
  }

  useEffect(() => {
    const fetchProfilePicture = async () => {
      if (userId) {
        try {
          const response = await axios.get(
            `https://coblog-backend.vercel.app/users/profilePicture`,
            {
              headers: {
                "Content-Type": "application/json",
              },
              params: {
                userId: userId, // Send userId as query parameter
              },
            }
          );

          if (response.data && response.data.profilePictureUrl) {
            setProfilePictureUrl(response.data.profilePictureUrl);
          } else {
            setProfilePictureUrl(""); // Handle no profile picture
          }
        } catch (error) {
          setProfilePictureUrl(""); // Handle errors
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    fetchProfilePicture();
  }, [userId]);

  return (
    <div className="user-head">
      <div className="left">
        <div className="right-box">
          {isLoading ? (
            <p>Loading...</p>
          ) : profilePictureUrl ? (
            <img
              src={profilePictureUrl}
              alt="Profile"
              className="user-head-photo"
            />
          ) : (
            <p>No Profile Picture</p>
          )}
        </div>
        <div className="mid-name-box">
          <span className="user-name">{name}</span>
          <span className="date-created">
            Created On: {date.toLocaleDateString()}
          </span>
        </div>
      </div>
      <div className="right">
        <button className="Follow-btn-Blogtemplate">Follow</button>
        <div className="end-box">
          <img
            src={dotsIcon}
            alt="Menu"
            className="dots-icon"
            onClick={openDotclick}
          />
          <DotclickAccoun
            show={showDotclick}
            onClose={closeDotclick}
            blogLink={blogLink}
            blogId={blogId}
          />
        </div>
      </div>
    </div>
  );
};

export default UserHeadAccount;
