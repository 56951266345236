import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "../StyleSheets/Login.css";
import loadgif from "../DesktopComponents/Blog/AjaxLoader.gif";
import { useUser } from "../DesktopComponents/Contexts/UserContext";
import Blogbooklogo from "../Assets/Logo.png";

//
//
//

function Login() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    rememberMe: true,
  });

  const [loading, setLoading] = useState(false);
  const { user, setUser } = useUser();
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    setError("");

    if (formData.password === "") {
      setError("Enter your password");
      return;
    }

    if (formData.email === "") {
      setError("Please enter your email");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        "https://coblog-backend.vercel.app/auth/login",

        {
          email: formData.email,
          password: formData.password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setLoading(false);
      localStorage.setItem(
        "user",
        JSON.stringify({
          userId: response.data.user_id,
          email: response.data.email,
          name: response.data.username,
        })
      );
      setUser({
        userId: response.data.user_id,
        email: response.data.email,
        name: response.data.username,
        isLoggedIn: true,
      });
      navigate("/");
    } catch (error) {
      console.error("Failed to Login account:", error.message);
      setError("Failed to login. Please try again.");
      setLoading(false);
    }
  };

  return (
    <section className="login-section">
      <div className="login-container">
        <img
          src={Blogbooklogo}
          alt="Blogbooklogo"
          className="Blogbbook-logo-login"
        ></img>
        {/* <h1 className="login-title">Login</h1> */}
        <form className="login-form">
          <div>
            <label htmlFor="email" className="login-label">
              Enter email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              className="login-input"
              placeholder="Ex:jamil@gmail.com"
              required
            />
          </div>
          <div>
            <label htmlFor="password" className="login-label">
              Password
            </label>
            <input
              type="password"
              name="password"
              id="password"
              value={formData.password}
              onChange={handleChange}
              className="login-input"
              placeholder="Ex: 1234@GHk"
              required
            />
          </div>
          <div className="login-checkbox-container">
            <input
              id="rememberMe"
              name="rememberMe"
              type="checkbox"
              checked={formData.rememberMe}
              onChange={handleChange}
              className="login-checkbox-input"
            />
            <label htmlFor="rememberMe" className="login-checkbox-label">
              Remember Me
            </label>
          </div>
          {error && <div className="login-error">{error}</div>}
          <div className="login-button-container">
            <button
              onClick={handleLogin}
              className="login-button"
              disabled={loading}
            >
              {loading ? (
                <img src={loadgif} alt="Loading..." className="loading-gif" />
              ) : (
                "Login"
              )}
            </button>
          </div>
          <p className="login-signup-text">
            Don't have an account?{" "}
            <Link to="/signup" className="login-signup-link">
              Sign up here
            </Link>
          </p>
        </form>
      </div>
    </section>
  );
}

export default Login;
