import React from "react";
import CreateNewBlog from "../DesktopComponents/Blog/CreateNewBlog";

function CreatePage() {
  return (
    <div className="home-page-container">
      <CreateNewBlog />
    </div>
  );
}

export default CreatePage;
